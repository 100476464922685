<template>
  <Card
    :title="serialType + ' Details'"
    :rows="detailsTable"
  />
</template>
<script>
// import Vue from 'vue'
import {
  formatNumber, formatName, formatIssueStr, uniqueArray,
  formatSerialLink, simplifyUrl, dateSort, formatPublicationSearchQuery,
  // serialDetailsSort,
} from '@/utils'
import { encodeUrl } from '@/utils/utils'
// import URLGen from '@/utils/url-gen'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'SerialDetails',
  components: {
    Card: () => import(/* webpackChunkName: "journal_card" */ '@/components/journal/JournalCard.vue'),
  },
  // title: 'ProfileDetails',
  props: {
    oldJourId: { // If journalId= is in the query string, it ends up here
      type: Number,
      required: false,
      default: -1,
    },
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fullName: 'Loading...',
      // Details
      abbr: '',
      internal: false,
      publisher: '',
      websites: [],
      issn: {
        print: '',
        online: '',
        other: '',
      },
      frequency: '',
      isRefList: false,
      refListStart: -1,
      publicationsCited: -1,
      citationCount: -1,
      citationPublications: -1,
      totalPublications: -1,
      latest: {
        text: '',
        id: -1,
      },
      earliest: {
        text: '',
        id: -1,
      },
      coverage: '',
      note: '',

      related: [],
      // history: [],
      recent: [
        // {title, volume, issue, year, id}
      ],
      jourId: -1,
      jourIdLookup: {
        // jourId: {}
      },
      jourCodeLookup: {

      },
      visited: {}, // Building history for Series

      // Rebuilding....
      allDetails: {}, // relId, details
      firstSerialId: -1,

      groupAbbrLookup: {},
      internalNotes: [],
      currentStr: ' - ',

      serialUrl: '/mathscinet/search/serials/profile?',
      serialQueryString: {
        series: 'seriesId=',
        journal: 'journalId=',
        group: 'groupId=',
      },
      paperUrl: '/mathscinet/search/publdoc.html?pg4=MR&s4=',
      pubUrl: '/mathscinet/search/publications.html?',
      pubQueryString: {
        series: 'pg4=SE&s4=',
        group: 'groupid=',
        issue: 'pg1=ISSI&s1=',
        cited: 'caller=mrcit',
      },
      failedGroupIds: [], // Journal IDs that returned a null Group ID
    }
  },
  computed: {
    instCode: function() {
      return this.$store.getters.appConfig.institution.code
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
    isJournal: function() {
      return ((this.oldJourId && this.oldJourId > -1) || (this.groupId && this.groupId > -1))
    },
    serialType: function() {
      return this.isJournal ? 'Journal' : 'Series' // Upper Case for simplicity
    },
    startRelId: function() {
      let id = this.isSeries ? this.seriesId : this.oldJourId
      // Check if it's a group instead (which wouldn't have an oldJourId)
      if (id === -1) {
        id = this.firstSerialId
        // Double check that this ID is actually in order when it came in from the API call (I think they're out of order when dates are N/A)
        let continuedAs = this.allDetails[this.genRelId(id, false)]
        if (continuedAs !== undefined && continuedAs.related !== null) {
          let related = continuedAs.related.filter(x => x.reltype === 'Continued as')
          let next = related.length > 0 ? this.allDetails[this.genRelId(related[0].relid, false)] : false
          while (next && next.groupId && next.groupId === this.groupId) {
            id = next.jourid
            continuedAs = this.allDetails[this.genRelId(id, false)]
            if (continuedAs === undefined) {
              break
            }
            related = continuedAs.related.filter(x => x.reltype === 'Continued as')
            next = related.length > 0 ? this.allDetails[this.genRelId(related[0].relid, false)] : false
          }
        }
      }
      return this.genRelId(id, this.isSeries)
    },
    detailsOrder: function() {
      const order = []
      // get the original
      // const startRelId = this.originRelId()
      // const startRelId = this.genRelId(this.firstSerialId, this.isSeries)
      // const startDetails = this.allDetails[startRelId]

      order.push(this.startRelId)
      // console.log('Updating Details Order!')
      // Find what's newer
      let latestDetails = this.allDetails[this.startRelId]
      // console.log(Object.keys(this.allDetails))
      if (latestDetails === undefined) {
        if (Object.keys(this.allDetails).length === 1) {
          // console.log('Undefined startRelId? ' + this.startRelId)
          return [this.genRelId(Object.values(this.allDetails)[0], this.isSeries)]
        } else {
          // console.log('Unable to get latest details from start: ' + this.startRelId)
          return [] // Not finished loading
        }
      }

      while (latestDetails.related && latestDetails.related.filter(x => x.reltype === 'Continued as').length > 0) {
        const relation = latestDetails.related.filter(x => x.reltype === 'Continued as')[0]
        const isSeries = 'relkey' in relation && relation.relkey === 'SER'
        const continuedRelId = this.genRelId(relation.relid, isSeries)
        latestDetails = this.allDetails[continuedRelId]
        if (latestDetails === undefined) {
          // console.log('Bailing at Latest')
          return [] // Not finished loading
        }
        order.unshift(continuedRelId) // Appends to the front of the order
      }
      // Find what's older
      let oldestDetails = this.allDetails[this.startRelId]
      if (oldestDetails === undefined) {
        // console.log('Bailing at Oldest')
        return [] // Not finished loading
      }
      while (oldestDetails.related && oldestDetails.related.filter(x => x.reltype === 'Formerly').length > 0) {
        const relation = oldestDetails.related.filter(x => x.reltype === 'Formerly')[0]
        const isSeries = 'relkey' in relation && relation.relkey === 'SER'
        const formerlyRelId = this.genRelId(relation.relid, isSeries)
        oldestDetails = this.allDetails[formerlyRelId]
        if (oldestDetails === undefined) {
          // console.log('Bailing at oldest 2')
          // console.log(formerlyRelId)
          return [] // Not finished loading
        }
        order.push(formerlyRelId) // Appends to the end of the order
      }
      // return the array of IDs
      // console.log('Working order')
      return order
    },
    groupDetailsTable: function() {
      const loaded = this.detailsOrder.length > 0
      if (!loaded) {
        const unloaded = [
          ['Current Title', 'N/A'],
          ['Current Abbr', 'N/A'],
          ['Publications Listed', 'N/A'],
          ['Publications Cited', 'N/A'],
          ['Citations', 'N/A'],
        ]
        if (this.groupId > -1) {
          return unloaded
        } else {
          return []
        }
      }

      const table = []

      const latestDetails = this.allDetails[this.detailsOrder[0]]
      // Determine if the serial was continued as something else
      let latestId = -1
      const latestIsSeries = Object.keys(latestDetails).includes('serid')
      const latestType = latestIsSeries ? 'series' : 'journal'
      if (latestIsSeries) {
        latestId = latestDetails.serid
      } else {
        latestId = latestDetails.jourid
      }

      if (this.detailsOrder.length > 1) {
        // const latestTitle = this.isSeries ? latestDetails.title : latestDetails.jortitle
        // const latestAbbr = this.isSeries ? latestDetails.shortTitle : latestDetails.ojorabbr
        const latestTitle = latestIsSeries ? latestDetails.title : latestDetails.jortitle
        const latestAbbr = latestIsSeries ? latestDetails.shortTitle : latestDetails.ojorabbr
        // Want to avoid making title/abbr a link unless it goes somewhere else.
        let makeLink = false
        if ((this.oldJourId > -1 && latestDetails.jourid !== this.oldJourId) || (this.seriesId > -1 && latestDetails.serid !== this.seriesId) || (this.groupId > -1 && latestDetails.groupId !== this.groupId)) {
          makeLink = true
        }

        table.push([
          {
            text: 'Current Title',
            attr: {
              title: 'Title of the latest version of this ' + this.serialType,
            },
          },
          // makeLink ? formatSerialLink(latestTitle, latestId, this.isSeries ? 'series' : 'journal') : latestTitle,
          makeLink ? formatSerialLink(latestTitle, latestId, latestIsSeries ? 'series' : 'journal') : latestTitle,
        ])
        table.push([
          {
            text: 'Current Abbr',
            attr: {
              title: 'Abbreviation of the latest version of this ' + this.serialType,
            },
          },
          // makeLink ? formatSerialLink(latestAbbr, latestId, this.isSeries ? 'series' : 'journal') : latestAbbr,
          makeLink ? formatSerialLink(latestAbbr, latestId, latestIsSeries ? 'series' : 'journal') : latestAbbr,
        ])
      }

      const seriesIds = this.detailsOrder.filter(x => x.toLowerCase().includes('s')).map(x => x.substr(1))
      const journalIds = this.detailsOrder.filter(x => x.toLowerCase().includes('j')).map(x => x.substr(1))
      const totalPublications = Object.values(this.allDetails).map(x => x.totalPublications).reduce((a, b) => a + b, 0)
      const pubArgs = {}
      if (seriesIds.length > 0) {
        pubArgs.seriesId = seriesIds
      }
      if (journalIds.length > 0) {
        pubArgs.journalId = journalIds
      }

      if (this.detailsOrder.length > 1) {
        table.push(
          [
            'Publications Listed',
            loaded ? {
              text: formatNumber(totalPublications),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: this.isSeries ? `si:(${seriesIds.join(' OR ')})` : `ji:(${journalIds.join(' OR ')})`,
                },
              },
            } : 'N/A',
          ]
        )
      }

      // This publicationsCited doesn't factor in situations where the Journal Group has a formerly that's a different journal group
      // const publicationsCited = loaded ? latestDetails.citationsDetails.citedPubCount : 0

      const groupIds = new Set(Object.values(this.allDetails).map(x => x.groupId || x.serid))
      const publishedPerGroup = [...groupIds].map(x => Object.values(this.allDetails).filter(y => y.groupId === x || y.serid === x)[0].citationsDetails?.citedPubCount)
      const publicationsCited = publishedPerGroup.reduce((a, b) => a + b, 0)

      const citationCount = loaded ? latestDetails.citationsDetails?.totalCitations : -1
      const citationPublications = loaded ? latestDetails.citationsDetails?.citingPubCount : -1

      const pubCitedPercentText = !loaded || latestDetails.totalPublications === 0 ? '' : ' (' + formatNumber((publicationsCited / totalPublications * 100).toFixed(1)) + '% of publications)'

      table.push(['Publications Cited', loaded && publicationsCited > -1 ? formatNumber(publicationsCited) + pubCitedPercentText : 'N/A'])
      table.push(['Citations', loaded && citationCount > -1 && citationPublications > -1 ? formatNumber(citationCount) + ' from ' + formatNumber(citationPublications) + ' publications' : 'N/A'])

      // Oldest/newest issue/volume for the Group
      if (this.detailsOrder.length > 1) {
        const refListYear = latestDetails.reflist === 'Y' ? latestDetails.firstYearReferenceList + ' to Present' : 'N/A'
        table.push(['<a href="/mathscinet/mrcit/journal_list.html" class="min-accessibility-spacing" title="View All Reference List Journals and Series">Reference Lists</a>', refListYear])

        let i = 1
        let oldestDetails = this.allDetails[this.detailsOrder[this.detailsOrder.length - i]]
        while (oldestDetails.oldest.results.length === 0 && i < this.detailsOrder.length) {
          // Skip N/A oldest
          i += 1
          oldestDetails = this.allDetails[this.detailsOrder[this.detailsOrder.length - i]]
        }

        let idType = 'Issue'
        if (this.isSeries) {
          idType = 'Volume'
        }

        // Latest
        table.push([
          {
            text: 'Latest ' + idType,
            attr: {
              title: 'The most current ' + idType + ' in MathSciNet',
            },
          },
          this.formatVolIssForTable(latestDetails, latestDetails.newest.results[0]),
        ])

        // Oldest / Earliest
        table.push([
          {
            text: 'Earliest ' + idType,
            attr: {
              title: 'The oldest ' + idType + ' in MathSciNet',
            },
          },
          // this.formatVolIssForTable(oldestDetails, latestDetails.oldest[0]), // Think this was a typo
          this.formatVolIssForTable(oldestDetails, oldestDetails.oldest.results[0]),
        ])
      }

      return table
    },
    individualDetailsTable: function() {
      // Used for Issue/Volume
      let idType = 'Issue'
      // let idUrl = this.pubUrl + this.pubQueryString.issue
      if (this.isSeries) {
        idType = 'Volume'
        // idUrl = this.paperUrl
      }
      const rows = []
      // Common elements first
      const loaded = this.detailsOrder.length > 0
      if (!loaded) {
        rows.push(['Title', 'N/A'])
        rows.push(['Abbreviation', 'N/A'])
        rows.push(['Publisher', 'N/A'])
        rows.push(['Websites', 'N/A'])
        if (this.isSeries) {
          rows.push(['ISSN', 'N/A'])
        } else {
          rows.push(['ISSN (Print)', 'N/A'])
          rows.push(['ISSN (Online)', 'N/A'])
          rows.push(['Frequency', 'N/A'])
        }
        rows.push(['Publications Listed', 'N/A'])
        rows.push(['<a href="/mathscinet/mrcit/journal_list.html" class="min-accessibility-spacing" title="View All Reference List Journals and Series">Reference Lists</a>', 'N/A'])
        rows.push([
          {
            text: 'Latest ' + idType,
            attr: {
              title: 'The most current ' + idType + ' in MathSciNet',
            },
          },
          'N/A',
        ])
        rows.push([
          {
            text: 'Earliest ' + idType,
            attr: {
              title: 'The oldest ' + idType + ' in MathSciNet',
            },
          },
          'N/A',
        ])
        rows.push(['Coverage', 'N/A'])
        return rows
      }

      let targetDetails = this.allDetails[this.detailsOrder[0]]
      if (this.oldJourId > -1) {
        targetDetails = this.allDetails[this.genRelId(this.oldJourId, this.isSeries)]
      }
      if (this.seriesId > -1) {
        targetDetails = this.allDetails[this.genRelId(this.seriesId, this.isSeries)]
      }
      if (this.isJournal && this.groupId !== targetDetails.groupId) {
        const possibleTargets = Object.values(this.allDetails).filter(x => x.groupId === this.groupId).map(x => this.genRelId(x.jourid))
        const filteredOrder = this.detailsOrder.filter(x => possibleTargets.includes(x))
        targetDetails = this.allDetails[filteredOrder[0]]
      }

      rows.push(['Title', this.isSeries ? targetDetails.title : targetDetails.jortitle])

      const abbrContent = loaded ? this.getAbbr(targetDetails) : undefined

      rows.push(['Abbreviation', loaded ? abbrContent : 'N/A'])
      rows.push(['Publisher', loaded && targetDetails.publisher !== null ? uniqueArray(formatName(targetDetails.publisher)) : 'N/A'])
      const websites = loaded ? this.getWebsites(targetDetails) : []
      rows.push(
        [
          'Websites',
          (loaded && websites.length > 0) ? websites.map(
            (url) => {
              const encodedUrl = (url) => {
                if (!url) {
                  return ''
                }
                // return url.replace(/&/g, '%26').replace(/;/, '%3B')
                return encodeUrl(url)
              }
              return {
                // url: this.instCode.trim() === 'CWREU*441' ? url : `https://mathscinet.ams.org/mathscinet/leavingmsn?url=${encodedUrl(url)}`,
                url: url,
                text: simplifyUrl(url),
                leavingmsn: true,
              }
            }
          ) : 'N/A',
        ]
      )

      if (this.isSeries) {
        rows.push(['ISSN', loaded && targetDetails.issn ? targetDetails.issn : 'N/A'])
      } else {
        rows.push(['ISSN (Print)', loaded && targetDetails.issn ? targetDetails.issn : 'N/A'])
        rows.push(['ISSN (Online)', loaded && targetDetails.eissn ? targetDetails.eissn : 'N/A'])
        rows.push(['Frequency', loaded && targetDetails.freq ? targetDetails.freq : 'N/A'])
      }

      rows.push([
        'Publications Listed',
        loaded ? {
          text: formatNumber(targetDetails.totalPublications),
          // url: this.isSeries ? URLGen.publications({ seriesId: targetDetails.serid }) : URLGen.publications({ journalId: targetDetails.jourid }),
          to: {
            name: 'PublicationsSearch',
            query: {
              query: this.isSeries ? `si:${targetDetails.serid} ` : `ji:${targetDetails.jourid} `,
            },
          },
        } : 'N/A',
      ])

      // Start Year to End Year
      let refListYear = 'N/A'
      if (loaded && targetDetails.reflist === 'Y') {
        let start = targetDetails.firstYearReferenceList
        if (targetDetails.oldest.results.length > 0 && targetDetails.firstYearReferenceList < targetDetails.oldest.results[0].year) {
          start = targetDetails.oldest.results[0].year
        }
        let end = 'Present'
        if (targetDetails.newest.results.length > 0 && targetDetails.active !== 'CURRENT') {
          end = targetDetails.newest.results[0].year
        }
        refListYear = start + ' to ' + end
      }
      rows.push(['<a href="/mathscinet/mrcit/journal_list.html" class="min-accessibility-spacing" title="View All Reference List Journals and Series">Reference Lists</a>', refListYear])

      // Continued As if needed
      if (targetDetails.related && targetDetails.related.filter(x => x.reltype === 'Continued as').length > 0) {
        const continuedAs = targetDetails.related.filter(x => x.reltype === 'Continued as')[0]
        rows.push(['Continued as', formatSerialLink(continuedAs.relabbrev, continuedAs.relid, 'relkey' in continuedAs && continuedAs.relkey === 'SER' ? 'series' : 'journal')])
      }

      // Latest
      rows.push([
        {
          text: 'Latest ' + idType,
          attr: {
            title: 'The most current ' + idType + ' in MathSciNet',
          },
        },
        targetDetails.newest.results.length > 0 ? this.formatVolIssForTable(targetDetails, targetDetails.newest.results[0]) : 'N/A',
      ])

      // Oldest / Earliest
      rows.push([
        {
          text: 'Earliest ' + idType,
          attr: {
            title: 'The oldest ' + idType + ' in MathSciNet',
          },
        },
        targetDetails.oldest.results.length > 0 ? this.formatVolIssForTable(targetDetails, targetDetails.oldest.results[0]) : 'N/A',
      ])

      // Formerly if needed
      if (targetDetails.related && targetDetails.related.filter(x => x.reltype === 'Formerly').length > 0) {
        const formerly = targetDetails.related.filter(x => x.reltype === 'Formerly')[0]
        rows.push(['Formerly', formatSerialLink(formerly.relabbrev, formerly.relid, 'relkey' in formerly && formerly.relkey === 'SER' ? 'series' : 'journal')])
      }

      let coverage = ''
      // if (targetDetails.highden) {
      //   coverage = 'Cover-to-cover'
      //   if (targetDetails.active === 'HISTORICAL') {
      //     coverage = 'No longer indexed'
      //   }
      // }
      if (targetDetails.active === 'HISTORICAL') {
        coverage = 'No longer indexed'
      } else if (targetDetails.highden) {
        coverage = 'Cover-to-cover'
      } // else (not historical or highden) partially covered

      if (coverage.length > 0) {
        rows.push(['Coverage', coverage])
      }

      if (loaded && targetDetails.note && targetDetails.note.length > 0) {
        // Test with groupId=3222
        rows.push(['Note', targetDetails.note])
      }

      return rows
    },

    detailsTable: function() {
      const rows = []
      if (this.detailsOrder.length > 1) {
        // rows.push(['<h4 class="text-ams-orange" title="Statistics only relevant to ' + instanceText + '">Instance</h4>', ''])
        rows.push(...this.individualDetailsTable)
        // rows.push(['<h4 class="text-ams-orange" title="Statistics relevant to the entire history of this ' + this.serialType + '">Group</h4>', ''])
        // rows.push(...this.groupDetailsTable)
        // let instanceText = 'the latest ' + this.serialType
        // if (this.oldJourId > -1 || this.seriesId > -1) {
        // instanceText = 'the specified ' + this.serialType
        // }
      } else {
        // The order might change more depending on what makes the most sense.
        rows.push(...this.individualDetailsTable)
        rows.push(...this.groupDetailsTable)
      }
      return rows
    },

    history: function() {
      const pendingHistory = []
      if (this.detailsOrder.length === 0) {
        return pendingHistory
      }
      const details = this.detailsOrder.map(x => this.allDetails[x])

      const firstJourId = this.isSeries ? -1 : details.filter(x => x.groupId === this.groupId)[0].jourid
      // console.log(firstJourId)
      for (const i in details) {
        const isSeries = details[i].serid !== undefined
        const id = isSeries ? details[i].serid : details[i].jourid
        let title = isSeries ? details[i].shortTitle : details[i].ojorabbr
        if (
          (isSeries && id !== this.seriesId) ||
          (!isSeries &&
            ((this.oldJourId > -1 && id !== this.oldJourId) ||
            (this.oldJourId === -1 && id !== firstJourId))
          )
        ) {
          title = formatSerialLink(title, id, isSeries ? 'series' : 'journal')
        } else {
          // console.log('Not formatting serial link for ' + id)
        }
        let oldest = 'N/A'
        if (details[i].oldest.results.length > 0 && details[i].oldest.results[0].year) {
          oldest = details[i].oldest.results[0].year
        }
        let newest = 'N/A'
        if (details[i].active === 'CURRENT') {
          // newest = '<span title="Active publication">&#8212;</span>'
          newest = '&#8212;'
        } else if (details[i].newest.results.length > 0 && details[i].newest.results[0].year) {
          newest = details[i].newest.results[0].year
        }
        pendingHistory.push([title, oldest, newest])
      }
      return pendingHistory
    },
  },
  mounted: function() {
    // this.getDetails()
    this.handleDetails()
  },
  methods: {
    getDetailPromise: async function(id, idType) {
      // idType = 'series', 'journal', 'group'
      if (id === undefined) {
        // it's the request for whatever series page you're on
        if (this.isSeries) {
          return SeriesAPI.details(this.seriesId)
        } else {
          if (this.groupId && this.groupId > -1) {
            return JournalAPI.details(this.groupId)
          }
          // Not using the journalId, because the whole history needs to be built up
        }
        return ''
      } else {
        if (idType === undefined) {
          // Assume it was generated by genRelId
          if (id[0] === 'J') {
            idType = 'journal'
          }
          if (id[0] === 'S') {
            idType = 'series'
          }
          id = id.substr(1)
        }
        if (idType === 'series') {
          return SeriesAPI.details(id)
        }
        if (idType === 'group') {
          return JournalAPI.details(id)
        }
        if (idType === 'journal') {
          if (this.failedGroupIds.includes(id)) {
            // Stop asking for it again...
            return ''
          }
          const groupId = await JournalAPI.getGroupId(id) // Will return a single groupId for a single journalId
          if (groupId === null) { // This should only be because of a database issue
            this.failedGroupIds.push(id)
            // Clear this journal id from the relations in this.allDetails
            for (const i in this.allDetails) {
              const relCount = this.allDetails[i].related.length
              // Assumes that there's not a Series and Journal with the same ID that are related to each other and having database issues.
              const validRelated = this.allDetails[i].related.filter((x) => x.relid !== parseInt(id))
              if (validRelated.length > 0) {
                this.allDetails[i].related.splice(0, relCount, ...validRelated)
              } else {
                // Just delete them all, no need to re-add
                this.allDetails[i].related.splice(0, relCount)
              }
            }
            return ''
          }
          if (groupId > -1) {
            return JournalAPI.details(groupId)
          }
          return ''
        }
      }
    },

    handleDetails: async function(details) {
      let original = false
      if (details === undefined) {
        // start with the default ID passed into this component
        details = await this.getDetailPromise()
        original = true
      }
      if (typeof (details) === 'string') {
        // Invalid data came back, likely from a journal that isn't released yet
        return
      }
      if ('results' in details) {
        details = details.results
      }

      // save the details
      for (const i in details) {
        const isSeries = details[i].jourid === undefined
        const id = details[i].jourid || details[i].serid
        if (this.firstSerialId === -1) {
          this.firstSerialId = id
        }
        const relId = this.genRelId(id, isSeries)
        this.$set(this.allDetails, relId, details[i])
      }
      // Check for any unsaved details
      const unsaved = []
      for (const i in details) {
        for (const r in details[i].related) {
          // Don't want every merge/translation, just Continued As and Formerly
          if (details[i].related[r].reltype === 'Formerly' || details[i].related[r].reltype === 'Continued as') {
            const isSeries = 'relkey' in details[i].related[r] && details[i].related[r].relkey === 'SER'
            const relId = this.genRelId(details[i].related[r].relid, isSeries)
            if (!(relId in this.allDetails)) {
              unsaved.push(relId)
            }
          }
        }
      }
      // request the unsaved journals/series
      for (const i in unsaved) {
        // Each one might have been saved by another request, so double check before requesting again
        if (unsaved[i] in this.allDetails) {
          continue
        }
        const subDetails = await this.getDetailPromise(unsaved[i])
        if (typeof (subDetails) === 'string') {
          // There was an error getting the sub details
          continue
        }
        this.handleDetails(subDetails)
      }

      this.sendToParent()
      if (original && !this.isSeries) {
        this.getInternalNotes()
      }
      // if(!this.isSeries && unsaved.length === 0) {
      //   this.getInternalNotes()
      // }
    },

    formatVolIssForTable: function(targetDetails, volIssData) {
      if (Object.keys(volIssData).indexOf('public') > -1 && !volIssData.public) {
        // If it's not public, then it won't have papers, so the search results would be empty
        return this.isSeries ? volIssData.year : formatIssueStr(volIssData)
      }
      const response = {
        text: 'N/A',
        to: undefined,
      }
      if (volIssData !== undefined) {
        if (this.isSeries) {
          response.text = volIssData.year
          response.to = {
            name: 'ArticlePage',
            query: {
              mr: volIssData.paperId,
            },
          }
        } else {
          response.text = formatIssueStr(volIssData)
          response.to = {
            name: 'PublicationsSearch',
            query: {
              query: `ji:${targetDetails.jourid} ` + formatPublicationSearchQuery(volIssData),
            },
          }
        }
      }
      return response
    },

    getAbbr: function(details) {
      return details.ojorabbr || details.shortTitle
    },

    getWebsites: function(details) {
      let websites = []
      if (details.urls !== undefined && details.urls !== null) {
        websites = details.urls.map(x => x.url)
      }
      if (details.url !== undefined && details.url !== null) {
        websites = details.url
      }
      return websites
    },

    genRelId: function(id, isSeries) {
      return (isSeries ? 'S' : 'J') + id
    },

    getInternalNotes: async function() {
      if (!this.$store.state.AppConfigModule.appConfig.internal) {
        return
      }
      // if (this.detailsOrder.length === 0) {
      //   return
      // }
      // let allJourIds = Object.keys(this.groupAbbrLookup)
      // let allJourIds = this.detailsOrder.map(x => this.allDetails[x]).filter(x => x.jourid !== undefined).map(x => x.jourid)
      const allJourIds = Object.values(this.allDetails).filter(x => x.jourid !== undefined).map(x => x.jourid)
      const rows = await JournalAPI.internalNotes(allJourIds)

      const noteInfo = []
      for (const i in rows) {
        // Need the journal id, text, dt, note, Initials
        // Need to sort the order
        for (const j in rows[i].notes) {
          noteInfo.push(
            {
              jourId: rows[i].id,
              date: rows[i].notes[j].dt,
              initials: rows[i].notes[j].initials,
              txt: rows[i].notes[j].txt,
            }
          )
        }
      }
      this.internalNotes = noteInfo.sort(function(a, b) { return dateSort(a, b) })
      this.sendToParent()
    },

    sendToParent: function() {
      let targetDetails = this.allDetails[this.detailsOrder[0]]

      const recent = {
        mostRecentJournalId: targetDetails.jourid,
        items: targetDetails.newest.results,
      }

      if (this.oldJourId > -1) {
        targetDetails = this.allDetails[this.genRelId(this.oldJourId, this.isSeries)]
      }
      if (this.seriesId > -1) {
        targetDetails = this.allDetails[this.genRelId(this.seriesId, this.isSeries)]
      }
      if (this.groupId > -1 && targetDetails.groupId !== this.groupId) {
        // multiple journal groups
        targetDetails = this.detailsOrder.map(x => this.allDetails[x]).filter(x => x.groupId === this.groupId)[0]
      }
      if (targetDetails === undefined) {
        // Not loaded yet, but why?
        // console.log('sendToParent not loaded')
        // console.log(this.detailsOrder)
        // console.log(Object.keys(this.allDetails))
        // console.log(this.startRelId)
        return
      }
      // TODO: Only partially updated
      const dataToReturn = {
        fullName: this.isSeries ? targetDetails.title : targetDetails.jortitle,
        abbr: this.isSeries ? targetDetails.shortTitle : targetDetails.ojorabbr,
        jourId: targetDetails.jourid,
        related: targetDetails.related,
        history: this.history,
        // recent: this.recent,
        recent,
        publisher: targetDetails.publisher,
        jourCode: targetDetails.jourcode,
        isRefList: targetDetails.reflist !== undefined && targetDetails.reflist !== null,
        internal: targetDetails.internal,
        internalNotes: this.internalNotes,
        jourCodeLookup: this.jourCodeLookup, // ???
        groupDetailsTable: this.groupDetailsTable, // rows for the Journal Group Details table, if it exists
      }

      // To use, you need the parent's <SerialDetails> to have @return="functionToHandleReturn"
      this.$emit('return', dataToReturn)
    },
  },
}
</script>
